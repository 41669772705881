import get from 'lodash/get';
import React from 'react';
import { LocalError } from 'helpers/errorTypes';
import Layout from 'modules/Layout';
import DataSources from 'modules/DataSources';
import Account from 'modules/Account';


function action({ route, query, getUrl, store }) {

  const { code, reference_id: referenceId, state } = query;
  const component = null;
  let redirect = null;

  if (code) {
    if (process.env.BROWSER) {
      const { p: provider, t: authType, i: accountId } = state ? JSON.parse(state) : {};
      if (authType === 'login') {
        store.dispatch(Account.actions.authContourCloud(code, referenceId));
      } else {
        const storeAccountId = get(store.getState(), 'account.account.accountId');
        if (storeAccountId === accountId) {
          store.dispatch(DataSources.actions.connect(code, referenceId, provider));
        } else {
          store.dispatch(DataSources.actions.connectError(new LocalError({ code: 'AccountMismatch' })));
        }

        if (authType === 'settings') {
          redirect = getUrl('account-settings-view', { view: 'dataSources' });
        } else if (authType === 'setup') {
          redirect = getUrl('data-sources');
        }
      }
    }
  } else {
    return null;
  }


  return {
    chunks   : ['account'],
    name     : route.name,
    redirect,
    component: (
      <Layout.components.LogoLayout>
        { component }
      </Layout.components.LogoLayout>
    ),
  };

}

export default action;
