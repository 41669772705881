exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UtIeV{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._2mjsy{width:225px;width:22.5rem;height:225px;height:22.5rem;fill:#30a8ff}", ""]);

// exports
exports.locals = {
	"loaderContainer": "UtIeV",
	"loader": "_2mjsy"
};