exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3UBvn{margin:0 0 6rem}._3UBvn,.w2UOe{position:relative;width:92px;width:9.2rem;height:92px;height:9.2rem}.w2UOe{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background-color:#c9cfdc;border-radius:50%;overflow:hidden}._3IeaY{width:auto;height:100%}._1Sh0o{bottom:0;right:0;width:22px;width:2.2rem;height:22px;height:2.2rem;overflow:visible}._1Sh0o,._1Sh0o:before{position:absolute;border-radius:50%}._1Sh0o:before{content:\" \";top:-2px;left:-2px;right:-2px;bottom:-2px;background:#f5f9ff}._1Sh0o .btn{height:100%;width:100%;line-height:1.1;opacity:1!important;border-radius:50%}._2_nqD{width:12px;width:1.2rem;height:12px;height:1.2rem;fill:#fff}.GkyZa{margin-bottom:16px;font-size:14px;font-size:1.4rem;line-height:18px;line-height:1.8rem}", ""]);

// exports
exports.locals = {
	"avatar": "_3UBvn",
	"avatar__imgWrapper": "w2UOe",
	"avatar__img": "_3IeaY",
	"avatar__btn": "_1Sh0o",
	"avatar__btn__icon": "_2_nqD",
	"marketingConsents": "GkyZa"
};